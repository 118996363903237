import styled from "styled-components";
import { TEXT_PRIMARY, BORDER, ORANGE_PRIMARY, RED, GREEN, WHITE, LINK_BULE } from "../../config/Constants";

export const DivFormLabelSet = styled.div`
  margin: 2rem 0 0 0;
`

export const LabelForTextField = styled.label`
  font-size: 1.125rem;
  display: block;
  margin-bottom: 0.5rem;
  color: ${TEXT_PRIMARY}
`

export const InputTextField = styled.input`
  box-sizing: border-box;
  height: 3rem;
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  width: 100%;
  border: 1px solid ${BORDER};
  &:focus {
    border: 1px solid ${ORANGE_PRIMARY};
    outline: 0;
  }
`

export const SelectField = styled.select`
  box-sizing: border-box;
  height: 3rem;
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  width: 100%;
  border: 1px solid ${BORDER};
  &:focus {
    border: 1px solid ${ORANGE_PRIMARY};
    outline: 0;
  }
`

export const InputSubmitButton = styled.input`
  height: 2.5rem;
  font-size: 1.125rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none;
  appearance: none;
  width: 100%;
  background-color: ${ORANGE_PRIMARY}};
  color: #fff;
  border: 1px solid ${ORANGE_PRIMARY};
  &:disabled{
    opacity: 0.3;
  }
`;

export const InputSubmitButtonSecondary = styled.input`
  height: 2.5rem;
  font-size: 1.125rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none;
  appearance: none;
  width: 100%;
  background-color: ${WHITE}};
  color: ${LINK_BULE};
  border: 1px solid ${LINK_BULE};
  &:disabled{
    opacity: 0.3;
  }
`;

export const PErrorMessage = styled.p`
  color: ${RED};
  font-size: 0.875rem;
`;

export const PValidMessage = styled.p`
  color: ${GREEN};
  font-size: 1.25rem;
`

export const TextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 16rem;
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  border: 1px solid ${BORDER};
  &:focus {
    border: 1px solid ${ORANGE_PRIMARY};
    outline: 0;
  }

`
